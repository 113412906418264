import { createRoot } from "react-dom/client"
import { IS_RECORDING_BOT } from "utils/bot"
import { initSentry } from "utils/sentry/initSentry"
import "./global.d"
import "./scriptversion"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import "./styles"

const renderApp = () => {
	import("./app")
		.then(({ App }) => {
			const container = document.getElementById("root")
			const root = createRoot(container!)
			root.render(<App />)
		})
		.catch(error => {
			if (error.name === "ChunkLoadError") {
				window.location.reload()
			} else {
				console.error("Failed to load app:", error)
			}
		})
}

;(function main() {
	if (!IS_RECORDING_BOT) {
		initSentry()
		serviceWorkerRegistration.register()
	}

	renderApp()
})()
